// Tab Slider

// core version + pagination modules:
import Swiper from '../node_modules/swiper/swiper-bundle.esm.browser.min.js';
import css from "../node_modules/swiper/swiper-bundle.css";

const paginationEl = document.querySelector('.swiper-pagination');
const tabTitle = document.querySelectorAll('.swiper-slide .tab-title');

// init Swiper:
const swiper = new Swiper('.swiper-container', {
  speed: 0,
  autoHeight: true,
  pagination: {
    el: paginationEl,
    clickable: true,
    bulletClass: 'swiper-pagination-customs',
    bulletActiveClass: 'swiper-pagination-customs-active',
    renderBullet: (index, className) => {
      // side navigation
      const slideTitle = tabTitle[index].textContent;

      return `<li class="${className}"><button class="tab-link">${slideTitle}</button></li>`;
    }
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

// Lang Switcher
const html = document.querySelector('html');
const currentLang = document.querySelector('.current-lang');
const langList = document.querySelector('.lang-list');
let langItems = langList.querySelectorAll('li');

currentLang.addEventListener('click', () => {
  currentLang.classList.toggle('active');
  langList.classList.toggle('show');
});

html.addEventListener('click', e => {
  if (e.target !== langList && e.target !== currentLang) {
    currentLang.classList.remove('active');
    langList.classList.remove('show');
  }
});

for (let i = 0; i < langItems.length; i++) {
  langItems[i].addEventListener('click', () => {
    Array.from(langItems).map(x => x.classList.remove('selected'));
    langItems[i].classList.add('selected');
    currentLang.textContent = langItems[i].textContent;
    html.setAttribute('lang', langItems[i].dataset.lang);
  });
}

// Hamburger menu
const hamburger = document.querySelector('.hamburger');
const mainMenu = document.querySelector('.nav-wrapper');
const body = document.querySelector('body');
const menuOverlay = document.querySelector('.menu-overlay');
let navItems = mainMenu.querySelectorAll('a');

function fadeToggle() {
  menuOverlay.style.transition = 'opacity .2s';
  const { opacity } = menuOverlay.ownerDocument.defaultView.getComputedStyle(menuOverlay, null);

  if (opacity === '1') {
    menuOverlay.style.opacity = '0';
  }
  else {
    menuOverlay.style.opacity = '1';
  }
}

hamburger.addEventListener('click', e => {
  hamburger.classList.toggle('is-active');
  mainMenu.classList.toggle('is-active');
  body.classList.toggle('overlayed');
  fadeToggle();
});

for (let i = 0; i < navItems.length; i++) {
  navItems[i].addEventListener('click', () => {
    hamburger.classList.remove('is-active');
    mainMenu.classList.remove('is-active');
    body.classList.remove('overlayed');
    fadeToggle();
  });
}
